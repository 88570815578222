import { fetcherAuth } from "@/libs/utils";
import { useAuthStore } from "@/stores/authStore";
import useSWR from "swr";
import { shallow } from "zustand/shallow";

type ReferralHistory = {
  id: string;
  name: string;
  img: string;
  description: string;
};

type UseReferralHistoryReturn = {
  data: ReferralHistory[] | null;
  isLoading: boolean;
  isError: any;
};

export default function useReferralHistory(): UseReferralHistoryReturn {
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const { data, error } = useSWR<UseReferralHistoryReturn>(
    isLoggedIn ? `/api/v1/users/history-affiliate` : null,
    fetcherAuth
  );

  return {
    data: data?.data || null,
    isLoading: !error && !data,
    isError: error,
  };
}
